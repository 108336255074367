import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import LearnHero from '../components/LearnHero';
import TestimonialCarousel from '../components/TestimonialCarousel';
import ReviewBar from '../components/ReviewBar';

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content has-text-centered">
          <h1 className="title is-size-1">404</h1>
          <h2>Oops! That page can’t be found.</h2>

          <p class="error-404-info">
            It seems we could not find the page you are looking for. Please
            check to make sure you have typed the URL correctly.
          </p>
          <Link to="/" class="button is-primary">
            {' '}
            Back to home{' '}
          </Link>
        </div>
      </div>
    </section>
    <LearnHero />
    <TestimonialCarousel />
    <ReviewBar />
  </Layout>
);

export default NotFoundPage;
